<style scoped>
    /* Set the page size to A4 and the orientation to landscape */
    @page {
        size: letter portrait;
    }

    /* Set the font size to 12px for printing */
    @media print {
        body {
            font-size: 12px;
        }

        .page-break {
            page-break-after: always;
        }
    }

    #logo {
        position: absolute;
        top: 2px;
        right: 2px;
        width: 200px;
        height: 100px;
    }

    #title {
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 20px;
    }

    table {
        font-family: arial, sans-serif;
        border-collapse: collapse;
        width: 100%;
    }

    #no-border {
        border: none;
    }

    td, th {
        border: 2px solid #000000;
        text-align: left;
        padding: 8px;
    }

    .remove_border tr th{
        border: none;
    }

    #table-list {
        margin-top: 80px;
    }

    #info {
        margin-top: 20px;
        width: 40%;
        float: left;
        margin-bottom: 20px;
    }

    .space-for-logo {
        width: 200px;
        height: 100px;
    }
</style>

<template>
    <div>
        <br>
        <!-- <div class="space-for-logo"></div> -->
        <p id="title">Nota Retur</p>
        <div id="info" class="w-full">
            <table class="remove_border w-full">
                <tr>
                    <th class="py-0"></th>
                    <th class="py-0"></th>
                    <th class="py-0"></th>
                    <th class="py-0"></th>
                    <th class="py-0">Nomor</th>
                    <th class="py-0">:</th>
                    <th class="py-0">{{ data.creditNote.Code }}</th>
                </tr>
                <tr>
                    <th class="py-0">Atas Faktur Pajak Nomor</th>
                    <th class="py-0">:</th>
                    <th class="py-0">{{ data.creditNote.TaxReturnNumber }}</th>
                    <th class="py-0"></th>
                    <th class="py-0">Tgl</th>
                    <th class="py-0">:</th>
                    <th class="py-0">{{ formatDate(data.creditNote.Date) }}</th>
                </tr>
            </table>
        </div>
        <div>
            <table class="remove_border">
                <tr>
                    <th colspan="3">PEMBELI</th>
                </tr>
                <tr>
                    <th class="py-0">Nama</th>
                    <th class="py-0">:</th>
                    <th class="py-0">{{ data.customer.name }}</th>
                </tr>
                <tr>
                    <th class="py-0">Alamat</th>
                    <th class="py-0">:</th>
                    <th class="py-0">{{ data.customer.address }}</th>
                </tr>
                <tr>
                    <th class="py-0">NPWP</th>
                    <th class="py-0">:</th>
                    <th class="py-0">{{ data.customer.npwp }}</th>
                </tr>
                <tr>
                    <th colspan="3">KEPADA PENJUAL</th>
                </tr>
                <tr>
                    <th class="py-0">Nama</th>
                    <th class="py-0">:</th>
                    <th class="py-0">{{ data.company.Name }} ({{ data.warehouse.name }})</th>
                </tr>
                <tr>
                    <th class="py-0">Alamat</th>
                    <th class="py-0">:</th>
                    <th class="py-0">{{ data.warehouse.address }}</th>
                </tr>
                <tr>
                    <th class="py-0">NPWP</th>
                    <th class="py-0">:</th>
                    <th class="py-0">{{ data.company.Npwp }}</th>
                </tr>
            </table>
        </div>
        <table class="table table-bordered" style="width:100%">
            <thead>
                <tr>
                    <th style="vertical-align: middle; text-align:center;">No. Urut</th>
                    <th style="vertical-align: middle; text-align:center;">Macam dan Jenis Barang Kena Pajak</th>
                    <th style="vertical-align: middle; text-align:center;">Kuantum</th>
                    <th style="vertical-align: middle; text-align:center;">Harga Satuan menurut Faktur Pajak</th>
                    <th style="vertical-align: middle; text-align:center;">Harga BKP yang dikembalikan (Rp)</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(tr, idxtr) in data.creditNoteLine" :key="idxtr">
                    <td style="vertical-align: middle; text-align:center;">{{ idxtr + 1 }}</td>
                    <td style="vertical-align: middle; text-align:center;">{{ tr.ItemName }}</td>
                    <td style="vertical-align: middle; text-align:center;">{{ tr.Qty }} {{ tr.ItemUnit }}</td>
                    <td style="vertical-align: middle; text-align:right;">{{ formatNumber(tr.PriceCn / tr.Qty) }}</td>
                    <td style="vertical-align: middle; text-align:right;">{{ formatNumber(tr.PriceCn) }}</td>
                </tr>
                <tr>
                    <td colspan="4">Jumlah Harga BKP yang dikembalikan</td>
                    <td style="text-align:right;">{{ formatNumber(data.creditNoteLine.reduce((prev, curr) => prev + curr.PriceCn, 0)) }}</td>
                </tr>
                <tr>
                    <td colspan="4">Dikurangi Potongan Harga</td>
                    <td style="text-align:right;">{{ formatNumber(data.creditNoteLine.reduce((prev, curr) => prev + curr.ReturnDiscount, 0)) }}</td>
                </tr>
                <tr>
                    <td colspan="4">Dasar Pengenaan Pajak</td>
                    <td style="text-align:right;">{{ formatNumber(data.creditNoteLine.reduce((prev, curr) => prev + curr.PriceCn, 0) - data.creditNoteLine.reduce((prev, curr) => prev + curr.ReturnDiscount, 0)) }}</td>
                </tr>
                <tr>
                    <td colspan="4">Pajak Pertambahan Nilai yang diminta kembali</td>
                    <td style="text-align:right;">{{ formatNumber(data.creditNoteLine.reduce((prev, curr) => prev + curr.ReturnTax, 0)) }}</td>
                </tr>
                <tr>
                    <td colspan="4">Pajak Penjualan Atas Barang Mewah yang diminta kembali</td>
                    <td style="text-align:right;"></td>
                </tr>
            </tbody>
        </table>
        <div class="w-full flex">
            <div class="w-1/2"></div>
            <div class="w-1/3 text-center">
                <div>{{ data.warehouse.city }}, {{ formatDate2(data.creditNote.Date) }}</div>
                <div>Pembeli</div>
                <div class="mt-12"></div>
                <div style="border-bottom: 1px solid black;"></div>
                <div>Direktur</div>
            </div>
            <div class="w-1/6"></div>
        </div>
        <div>Lembar ke-1 untuk Pengusaha Kena Pajak yang menerbitkan Faktur Pajak</div>
        <div>Lembar ke-2 untuk Pembeli</div>
    </div>
</template>

<script>
import moment from "moment-timezone";
// import logoSDN from "../../../assets/images/logo/logo-sdn.png";
export default {
    name: "PrintPdf",
    // props: {
    //     handover_id: Number
    // },
    data() {
        return {
            id: null,
            handover_ids: [],
            table: [],
            sto_plan_code: null,
            picking_code: null,
            selectedSrcWarehouse: null,
            selectedDestWarehouse: null,
            reqDeliveryDate: null,
            eta: null,
            // logoSDN: logoSDN,
            showInfo: false,
            data: {
                company: {},
                warehouse: {},
                customer: {},
                creditNote: {},
                creditNoteLine: [],
            },
        }
    },
    mounted() {
        moment.locale("id")
        this.print();
    },
    methods: {
        async print() {
            document.title = "Nota Retur Penjualan"
            await this.getData()
            await window.print()
        },
        async getData() {
            this.$vs.loading();
            // let resp = await this.$http.get(`/api/wms/v1/simple-outbound-planner/handover-data/${this.handover_id}`);
            let resp = await this.$http.get(`/api/v1/credit-note/data/${this.$route.params.id}`);
            if (resp.code == 200) {
                console.log(resp.data)
                this.data = resp.data
                this.$vs.loading.close();
            } else {
                this.$vs.loading.close();
            }
        },
    },
    computed: {
        formatDate: () => {
            return (val) => {
                const momentDate = moment.utc(val);
                // momentDate.add(1, 'days');              // Tambahkan 1 hari
                return momentDate.format('DD.MM.YYYY');
            };
        },
        formatDate2: () => {
            return (val) => {
                const momentDate = moment.utc(val);
                // momentDate.add(1, 'days');              // Tambahkan 1 hari
                return momentDate.format('DD MMMM YYYY');
            };
        },
        formatNumber: () => {
            return (val) => {
                return Intl.NumberFormat("en", {minimumFractionDigits: 2, }).format(val)
            }
        },
    },
}
</script>
